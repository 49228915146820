import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.png";
import navIcon1 from '../assets/img/discord.svg';
import navIcon2 from '../assets/img/instagram.svg';
import navIcon3 from '../assets/img/youtube.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
                <a href="https://discord.gg/bt7F45n8TD" target="_blank"><img src={navIcon1} alt="" /></a>
                <a href="https://www.instagram.com/_reptic_" target="_blank"><img src={navIcon2} alt="" /></a>
                <a href="https://www.youtube.com/channel/UC0M84q--CkZ_mVkJ3j4FO7A" target="_blank"><img src={navIcon3} alt="" /></a>
            </div>
            <p>Copyright © 2022 repticJS. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
