import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import RMedia_IMAGE from "../assets/img/RMedia.png";
import CALCULATOR_IMAGE from "../assets/img/calculator.png";
import CountBot_IMAGE from '../assets/img/CountBot.png'
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "RMedia",
      description: "RMedia is YouTube type clone. Completely redesigned. Build in Reactjs",
      imgUrl: RMedia_IMAGE,
      link: "https://RMedia.repticjs.com"
    },
    {
      title: "Calculator",
      description: "A Calculator build in Reactjs",
      imgUrl: CALCULATOR_IMAGE,
      link: "https://calculator.repticjs.com"
    },
  ];

  const discordbot_projects = [
    {
      title: "CountBot",
      description: "CountBot is a discord bot. Make counting channels for multiple gamemodes. Not just the generic +1, +1. Try multple gamemodes, including -1, -1, etc. Dashboard Coming Soon.",
      imgUrl: CountBot_IMAGE,
      link: "https://countbot.repticjs.com"
    },
  ]

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Here all are my projects that i spend time on. As I believe the more projects you build / create the better or the more fluent you will become.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">JavaScript, React</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Discord Bots, etc</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Other</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                    <p>On here i will put up projects that i have dont in javascript excluding Discord Bots.
                    <br></br>
                      Examples: Forms, Login System, etc.
                    </p>
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>Here i will be showing you everthing Discord Bot related. Going from different / unique discord bots to dashboards. Or just using the discord login api.</p>
                      <Row>
                        {
                          discordbot_projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>In this section labeled other I will be putting all the other projects that dont fit into the other 2 categories.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
