import { Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';


export const ProjectCard = ({ title, description, imgUrl, link }) => {

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <br></br>
          <br></br>
          <br></br>
          <Button variant="primary" onClick={event =>  openInNewTab(`${link}`) }>View Project</Button>
        </div>
      </div>
      
    </Col>
  )
}
